
import { Link, graphql } from "gatsby";
import React, {useState} from 'react';
import { useDispatch} from "react-redux"
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/residentsFooter';
import Header from '../../../components/UkResidents/HCP/header';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ScrollAnimation from 'react-animate-on-scroll';
import {showUkResidentsHcpDisclaimerPopup} from "../../../actions/ukResidents/hcp"
import {toggleUkResidentsHcpDisclaimerPopup} from "../../../controllers/ukResidents/hcp/disclaimer"
import {constructUkHcpContactFormEmailHtml, submitContactFormUkHcp} from "../../../controllers/ukResidents/hcp/mailer"
import Editor from "../../../components/Editor"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isEmailValid, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter} from "../../../util"
import ReCAPTCHA from "react-google-recaptcha";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultUkResidentsHcpDisclaimerPopupConfigs } from '../../../util/configs'
import SEO from "../../../components/seo"
import CircularProgress from "@material-ui/core/CircularProgress"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryContactUsTemplateUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulContactUsTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            bodyText {
                json
            }
        }
    }
`

const ContactUsTemplatePageUkHcp = (props) => {

    const dispatch = useDispatch();
    const recaptchaRef = React.createRef({})

    const {bannerImage, slug, title, bodyText} = props.data.contentfulContactUsTemplate;
    React.useEffect(() => {
    }, []);
    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showUkResidentsHcpDisclaimerPopup(actionConfig));
    }

    let [name, setName] = useState("")
    let [company, setCompany] = useState("")
    let [country, setCountry] = useState("")
    let [email, setEmail] = useState("")
    let [phone, setPhone] = useState("")
    let [phoneCode, setPhoneCode] = useState("")
    let [phoneCodeFilter, setPhoneCodeFilter] = useState("")
    let [whoReferred, setWhoReferred] = useState("")
    let [message, setMessage] = useState("")
    let [wasValidated, setWasValidated] = useState(false)
    let [submitPressed, setSubmitPressed] = useState(false)
    let [captchaValidated, setCaptchaValidated] = useState(false)
    let [submitError, setSubmitError] = useState(null)
    let [loading, setLoading] = useState(false)

    const clearForm = () => {
        setName("")
        setCompany("")
        setCountry("")
        setEmail("")
        setPhone("")
        setPhoneCode("")
        setPhoneCodeFilter("")
        setWhoReferred("")
        setMessage("")
        setWasValidated(false);
        setSubmitPressed(false);
        setCaptchaValidated(false);
        setSubmitError(null)
        setLoading(false)
    }

    const captchaOnChange = value => {
        if(isNullUndefined(value)){
            return;
        }

        setCaptchaValidated(true)
    }
    
    const captchaOnExpired = () => {
        toast.error("Recaptcha expired. Please reload to try again.");
        setLoading(false)
        setCaptchaValidated(false)
    }

    const captchaOnErrored = () => {
        toast.error("Something went wrong. Please try again.");
        setCaptchaValidated(false)
    }

    const submitForm = async () => {
        if(loading) return;

        setWasValidated(true);
        setSubmitPressed(true);
        setSubmitError(null)

        let formData = {name, company, country, email, phone, phoneCode, whoReferred, message};

        for(let attribute of Object.keys(formData)){
            if(formData[attribute].length < 1){
                setSubmitError({message: "Please fill in all required fields"})
                return;
            }
        }

        if(!captchaValidated){
            setSubmitError({message: "Please verify that you are not a robot"})
            return;
        }

        setLoading(true)

        let data = {
            subject: "UK Residents Health Care Professionals - Contact Form Response",
            html: constructUkHcpContactFormEmailHtml(formData)
        }

        try {
            await submitContactFormUkHcp(data)
            toast.success("Your response was submitted successfully");
            if(window && window.grecaptcha){
                window.grecaptcha.reset()
            }
            clearForm();
        }catch(e){
            setSubmitPressed(false);
            setLoading(false)
            toast.error("Something went wrong, please try again");
            if(window && window.grecaptcha){
                window.grecaptcha.reset()
            }
        }

    }

    return ( 
        <TemplateWrapper>
            <Header/>
            <SEO title={`${capitalizeFirstLetter(title)} | UK Residents | HCP`} />
              <ToastContainer
                position="top-right"
                autoClose={3500}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
      
            <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                    <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                
                               <h1 className="">{title}</h1>
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to="/UkResidents/HCP/home">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                               </div>
                           
                               </div>
                        </div>
            </div>
            <div className={styles.content}>
                    <div className="container pt-5 pb-5">
                    <div className="row">
                    <div className="col-12 col-sm-12 col-md-5">
                    <div className={styles.side_text}>
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 ">
                    <div className="form_wrap">
                        <p>
Please contact us using the following form.<br/>

Items that are marked with a star (*) are mandatory.</p>
<div className="row">
<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"text"} name={"name"} label={"Name"} value={name} required={true} onChange={e => setName(e.target.value)} variant={"outlined"} error={wasValidated && submitPressed && name.length < 1} />
</div> 
<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"text"} name={"company"} label={"Company"} value={company} required={true} onChange={e => setCompany(e.target.value)} variant={"outlined"} error={wasValidated && submitPressed && company.length < 1} />
</div> 
</div>   
<div className="row">

<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"select"} name={"country"} label={"Country"} value={country} required={true} onChange={(e, payload) => {
    setPhoneCode("")
    if(!isNullUndefined(payload)){
        setCountry(payload.value)
        setPhoneCodeFilter(payload.code)
    }
}} variant={"outlined"} error={wasValidated && submitPressed && country.length < 1} /> 
</div>
<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"text"} name={"email"} label={"Email"} value={email} required={true} onChange={e => setEmail(e.target.value)} variant={"outlined"} error={wasValidated && submitPressed && !isEmailValid(email)} />
</div> 
</div> 

<div className="row">
<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"select"} name={"phoneCode"} label={"Phone Code"} value={phoneCode} phoneCodeFilter={phoneCodeFilter} required={true} onChange={(e, payload) => setPhoneCode(payload.phone)} variant={"outlined"} error={wasValidated && submitPressed && phoneCode.length < 1} />

<Editor type={"text"} name={"phone"} label={"Phone Number"} value={phone} required={true} onChange={e => setPhone(e.target.value)} variant={"outlined"} error={wasValidated && submitPressed && phone.length < 1} />
</div> 
<div className="col-12 col-sm-12 col-md-12 col-lg-6">
<Editor type={"text"} name={"whoReferred"} label={"How you heard about us?"} value={whoReferred} required={true} onChange={e => setWhoReferred(e.target.value)} variant={"outlined"} error={wasValidated && submitPressed && whoReferred.length < 1} />
</div> 
</div>

<div className="row">
<div className="col-sm-12 col-md-12">
<Editor type={"text"} name={"message"} label={"Message"} value={message} required={true} onChange={e => setMessage(e.target.value)} variant={"outlined"} multiline={true} rows={4} rowsMax={6} error={wasValidated && submitPressed && message.length < 1} />
</div> 

</div> 
{!isNullUndefined(process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY) && <div className="row">
<div className="col-12">
    <ReCAPTCHA
        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
        onChange={captchaOnChange}
        onErrored={captchaOnErrored}
        onExpired={captchaOnExpired}
        ref={recaptchaRef}
    />
</div>
</div>}
<div className="row">
    <div className="col-12">
        {!isNullUndefined(submitError) && <div className="col-12">
            <small className="text-danger">
                {submitError.message}
            </small>
        </div>}
    </div>
</div>
<div className="row">
<div className="col-12"><br/>
<button className="custom_btn dark bordered_btn" onClick={submitForm}>
{loading ? <CircularProgress size={20}/> : "Submit"}
</button>
</div>
</div>
                    
     

</div>
                    
                    </div>
                 

                </div>
                    </div>
           </div>
         
            <Footer/>
        </TemplateWrapper>
     );
}

export default ContactUsTemplatePageUkHcp;